<template>
  <div class="hero-slider__slide" :class="{ 'is-contract': isContract }">
    <HeroSlideContent
      v-if="slideData"
      :slide-data="slideData"
      :isContract="isContract"
    />
    <NextButton
      v-if="isDesktop && sliderLength > 1"
      @changeSlide="(value) => $emit('changeSlide', value)"
      position="both"
    />
    <WideBanner
      :class="`${bannerData.isVideo ? '' : 'hero-slider__slide__image'}`"
      :data="bannerData"
      :squareVideo="squareVideo"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import HeroSlideContent from '~/components/Homepage/HeroSlider/HeroSlideContent.vue';
import NextButton from '~/components/Homepage/HeroSlider/NextButton.vue';
import WideBanner from '~/components/Editorial/Commons/WideBanner.vue';

export default defineComponent({
  name: 'HeroSlide',
  components: {
    HeroSlideContent,
    NextButton,
    WideBanner,
  },
  props: {
    slideData: {
      type: Object,
      required: true,
    },
    slideIndex: {
      type: Number,
      required: true,
    },
    sliderLength: {
      type: Number,
    },
    squareVideo: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isMobile, isDesktop, isDesktopOrTablet } = useWindow();
    const bannerData = {
      images: {
        desktop: props.slideData.image_desktop?.src ?? '',
        mobile: props.slideData.image_mobile?.src ?? '',
        alt: props.slideData.image_desktop?.alt ?? '',
      },
      video_url: props.slideData.video_url ?? '',
      video_url_mobile: props.slideData.video_url_mobile ?? '',
      isVideo: props.slideData.isVideo ?? false,
    };
    return { isMobile, isDesktop, isDesktopOrTablet, bannerData };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider__slide {
  width: 100%;
  height: 100%;
  @include desktop-boxed;
  max-height: 100vh;
  overflow: hidden;
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 2.5s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>

<style lang="scss">
.hero-slider__slide__content {
  position: absolute;
  z-index: 2;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 10px;
  padding-right: 10px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include from-desktop-min {
    padding-left: 1.25rem;
  }
  .custom-button {
    z-index: 2;
  }
  &__title {
    z-index: 2;
    @include mobile-h3;
    color: var(--c-white);
    @include from-desktop-min {
      @include desktop-h3;
      max-width: 83%;
    }
  }
  &__subtitle {
    z-index: 2;
    color: var(--c-white);
    @include mobile-h6;
    @include from-desktop-min {
      @include desktop-h6;
    }
  }
  &__cta {
    z-index: 2;
    &__title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: var(--c-white);
      &__text {
        @include mobile-h6;
        @include flex-center;
        color: var(--c-white);
      }
      &__subtext {
        @include paragraph-s;
        color: var(--c-white);
      }
    }
    @include from-desktop-min {
      &__title {
        &__text {
          @include desktop-h6;
        }
        &__subtext {
          @include paragraph-m;
        }
      }
    }
  }
}
.hero-slider__next {
  position: absolute;
  top: 29%;
  width: 100vw;
  @include desktop-boxed;
  z-index: 2;
  display: flex;
  &__button {
    @include label-l;
    @include pointer;
    @include flex-center;
    width: 5rem;
    height: 5rem;
    border: 1px solid var(--c-white);
    border-radius: 50%;
    color: var(--c-white);
    &__left {
      margin: 0 auto 0 2.375rem;
    }
    &__right {
      margin: 0 2.375rem 0 auto;
    }
  }
}
.is-contract.hero-slider__slide__content {
  @include from-desktop-min {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    width: 80vw;

    .hero-slider__slide__content__subtitle {
      padding-bottom: 1.25rem;
    }
  }
}

.is-contract {
  .hero-slider__slide__image {
    background: var(--c-black);
    @include from-desktop-min {
      .image-container {
        padding: 3.125rem 0;
        display: flex;
        justify-content: center;
        .image-container__wide-image {
          width: unset;
        }
      }
    }
  }
}
</style>
