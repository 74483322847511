<template>
  <div class="hero-slider__next">
    <div
      v-if="position === 'both' || position === 'left'"
      class="hero-slider__next__button hero-slider__next__button__left"
      @click="$emit('changeSlide', '<')"
    >
      {{ $t('Prev') }}
    </div>
    <div
      v-if="position === 'both' || position === 'right'"
      class="hero-slider__next__button hero-slider__next__button__right"
      @click="$emit('changeSlide', '>')"
    >
      {{ $t('Next') }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'NextButton',
  components: {},
  props: {
    position: {
      type: String,
      default: 'both',
      validator: (value) => ['right', 'left', 'both'].includes(value),
    },
  },
  setup() {},
});
</script>
