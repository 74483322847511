<template>
  <div
    class="hero-slider__slide__content"
    :class="{ 'is-contract': isContract }"
  >
    <span
      v-if="slideData.subtitle && isContract"
      class="hero-slider__slide__content__subtitle"
    >
      {{ slideData.subtitle }}
    </span>
    <HeroSlideCta v-if="slideData.cta" :cta="slideData.cta" />
    <span
      v-else-if="slideData.subtitle"
      class="hero-slider__slide__content__subtitle"
    >
      {{ slideData.subtitle }}
    </span>
    <HeroSlideTitle v-if="slideData.title" :title="slideData.title" />
    <CustomCta
      v-if="slideData.button && slideData.button.text"
      :link="localePath(slideData.button.href)"
      :target="slideData.button.target || '_blank'"
    >
      {{ slideData.button.text }}
    </CustomCta>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import HeroSlideTitle from '~/components/Homepage/HeroSlider/HeroSlideTitle.vue';
import HeroSlideCta from '~/components/Homepage/HeroSlider/HeroSlideCta.vue';
import CustomCta from '~/components/General/CustomCta.vue';

export default defineComponent({
  name: 'HeroSlideContent',
  components: {
    HeroSlideTitle,
    HeroSlideCta,
    CustomCta,
  },
  props: {
    slideData: {
      type: Object,
      required: true,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>
