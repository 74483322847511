<template>
  <section class="hero-slider" v-if="hasContentToShow">
    <div class="glide hero-slider__glide">
      <div class="glide__track hero-slider__glide__track" data-glide-el="track">
        <ul class="glide__slides hero-slider__glide__track__slides">
          <li
            class="glide__slide hero-slider__glide__track__slides__slide"
            :class="{ 'single-slide': heroSliderContent.length === 1 }"
            v-for="(slide, index) in heroSliderContent"
            :key="index"
          >
            <HeroSlide
              :slider-length="heroSliderContent.length"
              :slide-data="slide"
              :slide-index="index"
              @changeSlide="changeSlide"
              :squareVideo="squareVideo"
              :isContract="isContract"
            />
          </li>
        </ul>
      </div>
    </div>
    <ProgressBar
      v-if="heroSliderContent.length > 1"
      :current-slide="currentSlide"
      :total-length="heroSliderContent.length"
    />
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import Glide, { Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import HeroSlide from '~/components/Homepage/HeroSlider/HeroSlide.vue';
import ProgressBar from '~/components/Homepage/HeroSlider/ProgressBar.vue';
import { useWindow } from '~/composables';

export default defineComponent({
  name: 'HeroSlider',
  components: {
    HeroSlide,
    ProgressBar,
  },
  props: {
    sliderData: {
      type: [Array, Object],
    },
    squareVideo: {
      type: Boolean,
      default: false,
    },
    isContract: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();
    const linkLabel = 'link-label';
    const linkUrl = 'link-url';
    const heroSliderContent = ref([]);
    const glideHeroSlider = ref();
    const currentSlide = computed(() => glideHeroSlider.value?.index ?? 0);

    const setSlideContent = (data) => {
      if (data?.active) {
        heroSliderContent.value.push({
          active: data.active,
          title: data.title,
          subtitle: data.subtitle,
          video_url: data.video_url ?? '',
          video_url_mobile: data.video_url_mobile ?? '',
          isVideo: data.isVideo ?? false,
          ...(data.img
            ? {
                image_desktop: {
                  src: data.img?.desktop,
                  alt: data.img?.alt,
                },
                image_mobile: {
                  src: data.img?.mobile,
                  alt: data.img?.alt,
                },
              }
            : {}),
          ...(data.cta
            ? {
                cta: {
                  text: data.cta?.[linkLabel],
                  subtext: data.cta?.subtext,
                  href: data.cta?.[linkUrl],
                  target: data.cta?.target ?? '_blank',
                },
                button: {
                  text: data.link?.[linkLabel],
                  href: data.link?.[linkUrl],
                  target: data.link?.target ?? '_blank',
                },
              }
            : {}),
        });
      }
    };
    const updateHeroSliderContent = () => {
      if (props.sliderData && props.sliderData.length > 0) {
        props.sliderData.forEach((element) => {
          setSlideContent(element);
        });
      } else if (props.sliderData) {
        setSlideContent(props.sliderData);
      }
      heroSliderContent.value = heroSliderContent.value.filter((slide) =>
        slide.isVideo
          ? isDesktop.value
            ? slide?.video_url
            : slide?.video_url_mobile
          : isDesktop.value
          ? slide?.image_desktop?.src
          : slide?.image_mobile?.src
      );
    };

    const hasContentToShow = computed(
      () =>
        heroSliderContent.value &&
        Array.isArray(heroSliderContent.value) &&
        heroSliderContent.value.length > 0
    );

    const changeSlide = (value) => {
      glideHeroSlider.value.go(value);
    };

    onMounted(() => {
      updateHeroSliderContent();
      if (hasContentToShow.value) {
        let container = document.querySelector('.hero-slider__glide');
        if (container) {
          const glideOptions = {
            type: 'slider',
            animationDuration: 1000,
            gap: 0,
            perView: 1,
            peek: 0,
          };
          if (heroSliderContent.value.length === 1) {
            glideOptions.swipeThreshold = false;
            glideOptions.dragThreshold = false;
          }
          glideHeroSlider.value = new Glide(
            '.hero-slider__glide',
            glideOptions
          );
          glideHeroSlider.value.mount({ Swipe });
        }
      }
    });

    return {
      heroSliderContent,
      currentSlide,
      hasContentToShow,
      changeSlide,
    };
  },
});
</script>

<style lang="scss">
.hero-slider {
  width: 100%;
  @include desktop-boxed;
  position: relative;
  &__glide {
    height: 100%;
    &__track {
      height: 100%;
      &__slides {
        height: 100%;
        &__slide {
          height: auto;
          &.single-slide {
            cursor: auto;
          }
        }
      }
    }
  }
}
</style>
