<template>
  <div class="wide-banner">
    <div class="video-container" v-if="data && data.isVideo && correctVideo">
      <ClientOnly>
        <vueVimeoPlayer
          class="video-container__player"
          :class="{ square: squareVideo }"
          :options="vimeoOptions"
          :ref="name"
          v-bind="correctVideoOption"
        />
      </ClientOnly>
    </div>
    <div
      class="image-container"
      v-else-if="data && data.isVideo === false && data.images && correctImage"
    >
      <nuxt-img
        class="image-container__wide-image"
        :src="correctImage"
        :alt="data.images.alt ? data.images.alt : ''"
        :preload="index === 0"
        :loading="index === 0 ? 'eager' : 'lazy'"
        format="webp"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { useWindow } from '~/composables';

export default defineComponent({
  name: 'WideBanner',
  components: { vueVimeoPlayer },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    squareVideo: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'player',
    },
  },
  setup(props) {
    const { isDesktop } = useWindow();

    const vimeoOptions = {
      autoplay: true,
      controls: false,
      loop: true,
      autopause: false,
      muted: true,
    };

    const isUrl = computed(() =>
      props.data.video_url.includes('https') ? true : false
    );

    const correctVideo = computed(
      () => props.data[`video_url${isDesktop.value ? '' : '_mobile'}`]
    );

    const correctVideoOption = computed(() =>
      isUrl.value
        ? { 'video-url': correctVideo.value }
        : { 'video-id': correctVideo.value }
    );

    const correctImage = computed(() =>
      isDesktop.value ? props.data?.images?.desktop : props.data?.images?.mobile
    );

    return {
      isUrl,
      isDesktop,
      correctImage,
      vimeoOptions,
      correctVideo,
      correctVideoOption,
    };
  },
});
</script>

<style lang="scss" scoped>
.wide-banner {
  .video-container {
    position: relative;
    &__player {
      padding: 199% 0 0 0;
      position: relative;
      width: 100%;
      @include desktop-boxed;
      &.square {
        padding: 99% 0 0 0;
      }
    }
  }
  .image-container {
    &__wide-image {
      width: 100%;
      display: flex;
    }
  }
  @include from-desktop-min {
    .video-container {
      &__player {
        padding: 56.25% 0 0 0; // 56.25% -> 16:9
        &.square {
          padding: 99% 0 0 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.wide-banner .video-container__player {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
