<template>
  <div class="hero-slider__slide__content__title">
    {{ title }}
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeroSlideTitle',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>
