<template>
  <div class="hero-slider__progress">
    <div
      v-for="index in totalLength"
      :key="index"
      class="hero-slider__progress__element"
      :class="
        currentSlide + 1 >= index && 'hero-slider__progress__element--active'
      "
    >
      <div class="hero-slider__progress__element--internal" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    currentSlide: {
      type: Number,
      required: true,
    },
    totalLength: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.hero-slider {
  &__progress {
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 100%;
    background-color: transparent;
    display: flex;
    &__element {
      height: 100%;
      flex: 1 1 0px;
      opacity: 1;
      z-index: 2;
      &--internal {
        width: 0;
        height: 100%;
        transition: width 1s ease-in-out;
      }
      &--active {
        .hero-slider__progress__element--internal {
          width: 100%;
          height: 100%;
          background-color: var(--c-white);
        }
      }
    }
  }
}
</style>
