<template>
  <nuxt-link :to="localePath(`${cta.href}`)" :target="cta.target || '_blank'">
    <div class="hero-slider__slide__content__cta">
      <div class="hero-slider__slide__content__cta__title">
        <span
          v-if="cta.subtext"
          class="hero-slider__slide__content__cta__title__subtext"
        >
          {{ cta.subtext }}
        </span>
        <span class="hero-slider__slide__content__cta__title__text">
          <span> {{ cta.text }}</span>
          <IconArrowWhite
            v-if="cta.text"
            class="hero-slider__slide__content__cta__icon"
          />
        </span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { IconArrowWhite } from '~/components/General/Icons';

export default defineComponent({
  name: 'HeroSlideCta',
  components: {
    IconArrowWhite,
  },
  props: {
    cta: {
      type: Object,
      required: true,
    },
  },
});
</script>
